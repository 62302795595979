"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actionCreators = void 0;
exports.default = reducer;
exports.isLoaded = isLoaded;
exports.load = load;
exports.reset = reset;
var _redux = require("utils/redux");
var _module = require("../../utils/module");
var _editor = require("./editor");
var _tutorials = require("./tutorials");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux-example/player/');
var LOAD = type('LOAD');
var LOAD_SUCCESS = type('LOAD_SUCCESS');
var LOAD_FAIL = type('LOAD_FAIL');
var BACKBONE_PROGRESS = type('BACKBONE_PROGRESS');
var BACKBONE_PROGRESS_SUCCESS = type('BACKBONE_PROGRESS_SUCCESS');
var BACKBONE_PROGRESS_FAIL = type('BACKBONE_PROGRESS_FAIL');
var LOADING_COLORS = type('LOADING_COLORS');
var RESET = type('RESET');
var initialState = {};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        tutorialId: action.tutorialId,
        isPreview: action.isPreview,
        isStaticShare: action.isStaticShare,
        isMobile: action.isMobile,
        isIframeFirst: action.isIframeFirst,
        isIframeHidden: action.isIframeHidden,
        iframeHash: action.iframeHash,
        isPopup: action.isPopup,
        lang: action.lang,
        helpcenter: action.helpcenter,
        roleId: action.roleId,
        revision: action.revision,
        embedUrl: action.embedUrl,
        noMobileRedirect: action.noMobileRedirect
      });
    case LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true
      }, action.result);
    case LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: false,
        error: (0, _redux.getActionError)(action, 'other'),
        errorStatusCode: action.errorStatusCode
      });
    case LOADING_COLORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPrimaryColor: action.primaryColor,
        loadingSecondaryColor: action.secondaryColor
      });
    case _editor.UPDATE_JSON:
    case _editor.MERGE_JSON:
      // whenever editor saves, reset player
      // to force new load to fetch updated changes
      return initialState;
    case _tutorials.LOAD_AFTER_PRELOAD_TUTORIALS_PAGE:
      {
        // when leaving player and going back to tutorials page,
        // reset player state to force new fetch when returning to player,
        // to retrieve any changes done directly within tutorials page.
        return initialState;
      }
    case _tutorials.CREATE_TRANSLATIONS_SUCCESS:
    case _tutorials.SAVE_TUTORIAL_TRANSLATIONS_SUCCESS:
    case _tutorials.UPDATE_TRANSLATION_SUCCESS:
    case _tutorials.DELETE_TRANSLATION_SUCCESS:
      {
        // when updating translations in the Translations page,
        // reset player state to force new fetch when returning to player,
        return initialState;
      }
    case RESET:
      return initialState;
    default:
      return state;
  }
}
function isLoaded(globalState, tutorialId, isPreview, isStaticShare, isMobile, isIframeFirst, isIframeHidden, iframeHash, isPopup, lang, helpcenter, roleId, revision, embedUrl, noMobileRedirect) {
  return globalState.player && globalState.player.loaded && globalState.player.tutorialId === tutorialId && globalState.player.isPreview === isPreview && globalState.player.isStaticShare === isStaticShare && globalState.player.isMobile === isMobile && globalState.player.isIframeFirst === isIframeFirst && globalState.player.isIframeHidden === isIframeHidden && globalState.player.iframeHash === iframeHash && globalState.player.isPopup === isPopup && globalState.player.lang === lang && globalState.player.helpcenter === helpcenter && globalState.player.roleId === roleId && globalState.player.revision === revision && globalState.player.embedUrl === embedUrl && globalState.player.noMobileRedirect === noMobileRedirect;
}
function load(tutorialId, isPreview, isStaticShare, isMobile, isIframeFirst, isIframeHidden, iframeHash, isPopup, lang, helpcenter, roleId, revision, embedUrl, noMobileRedirect) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: function promise(client) {
      return client.get('/loadPlayer', {
        params: {
          tutorial_id: tutorialId,
          isPreview: isPreview,
          isStaticShare: isStaticShare,
          isMobile: isMobile,
          isIframeFirst: isIframeFirst,
          isIframeHidden: isIframeHidden,
          iframeHash: iframeHash,
          isPopup: isPopup,
          lang: lang,
          helpcenter: helpcenter,
          roleId: roleId,
          revision: revision,
          embedUrl: embedUrl,
          noMobileRedirect: noMobileRedirect
        }
      });
    },
    tutorialId: tutorialId,
    isPreview: isPreview,
    isStaticShare: isStaticShare,
    isMobile: isMobile,
    isIframeFirst: isIframeFirst,
    isIframeHidden: isIframeHidden,
    iframeHash: iframeHash,
    isPopup: isPopup,
    lang: lang,
    helpcenter: helpcenter,
    roleId: roleId,
    revision: revision,
    embedUrl: embedUrl,
    noMobileRedirect: noMobileRedirect
  };
}
function reset() {
  return {
    type: RESET
  };
}
var actionCreators = exports.actionCreators = {
  // used to show the progress bar indicator in React
  backboneProgress: function backboneProgress(fn) {
    return {
      types: [BACKBONE_PROGRESS, BACKBONE_PROGRESS_SUCCESS, BACKBONE_PROGRESS_FAIL],
      promise: function promise() {
        return new Promise(fn);
      }
    };
  },
  loadingColors: function loadingColors(primaryColor, secondaryColor) {
    return {
      type: LOADING_COLORS,
      primaryColor: primaryColor,
      secondaryColor: secondaryColor
    };
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;
exports.persistView = exports.persistExpandingCategoryList = exports.persistExpandElement = exports.importServerCookie = exports.getLocalState = void 0;
var _module = require("../../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/tutorials/persisted/');
var UPDATE = type('UPDATE');
var initialState = {
  views: undefined,
  expandElement: undefined
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case UPDATE:
      if (!action.value) return state;
      return action.value;
    default:
      return state;
  }
}
var getLocalState = exports.getLocalState = function getLocalState(state) {
  return state.tutorials.persisted;
};
var STORED_VERSION = 5;
var getStoredKey = function getStoredKey() {
  return "iorad/redux/modules/tutorials[v".concat(STORED_VERSION, "]");
};
var update = function update(value) {
  return {
    type: UPDATE,
    value: value
  };
};
var updateAndStore = function updateAndStore(value) {
  return function (dispatch) {
    if (__CLIENT__) {
      dispatch(update(value));
      try {
        var stored = {
          version: STORED_VERSION,
          value: value
        };
        document.cookie = getStoredKey() + '=' + JSON.stringify(stored) + ';path=/';
      } catch (err) {
        // 3rd party cookies/data are disabled
        console.error(err);
      }
    }
  };
};
var importServerCookie = exports.importServerCookie = function importServerCookie(cookies) {
  return function (dispatch) {
    var storedJsonString = cookies[getStoredKey()];
    if (storedJsonString) {
      try {
        var stored = JSON.parse(storedJsonString);
        if (stored.version === STORED_VERSION) {
          dispatch(update(stored.value));
        }
      } catch (err) {
        // 3rd party cookies/data are disabled or invalid JSON
        console.error(err);
      }
    }
  };
};
var persistView = exports.persistView = function persistView(tabName, tabView) {
  return function (dispatch, getState) {
    var state = getState();
    var localState = getLocalState(state);
    var localStateUpdated = _objectSpread(_objectSpread({}, localState), {}, {
      views: _objectSpread(_objectSpread({}, localState.views), {}, _defineProperty({}, tabName, tabView))
    });
    return dispatch(updateAndStore(localStateUpdated));
  };
};
var persistExpandElement = exports.persistExpandElement = function persistExpandElement(expandElement) {
  return function (dispatch, getState) {
    var state = getState();
    var localState = getLocalState(state);
    var localStateUpdated = _objectSpread(_objectSpread({}, localState), {}, {
      expandElement: expandElement
    });
    return dispatch(updateAndStore(localStateUpdated));
  };
};
var persistExpandingCategoryList = exports.persistExpandingCategoryList = function persistExpandingCategoryList(expandingCategoryList) {
  return function (dispatch, getState) {
    var state = getState();
    var localState = getLocalState(state);
    var localStateUpdated = _objectSpread(_objectSpread({}, localState), {}, {
      expandingCategoryList: expandingCategoryList
    });
    return dispatch(updateAndStore(localStateUpdated));
  };
};
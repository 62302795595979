"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearUserNotifications = void 0;
exports.default = reducer;
exports.saveUserNotificationSettings = exports.markUserNotifications = exports.getUserNotifications = void 0;
var _module = require("../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/userNotifications');
var GET_NOTIFICATIONS = (0, _module.makeRequestTypes)(type('GET_NOTIFICATIONS'));
var MARK_NOTIFICATIONS = (0, _module.makeRequestTypes)(type('MARK_NOTIFICATIONS'));
var CLEAR_NOTIFICATIONS = (0, _module.makeRequestTypes)(type('CLEAR_NOTIFICATIONS'));
var SAVE_NOTIFICATION_SETTINGS = (0, _module.makeRequestTypes)(type('SAVE_NOTIFICATION_SETTINGS'));
var initialState = {
  notifications: [],
  allSettings: [],
  NOTIFICATION: {},
  NOTIFICATION_CATEGORY: {},
  saving: false,
  loading: false,
  error: null,
  marking: false,
  marked: false
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case GET_NOTIFICATIONS[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case GET_NOTIFICATIONS[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        notifications: action.result.rows,
        allSettings: action.result.settingsRows,
        NOTIFICATION: action.result.NOTIFICATION,
        NOTIFICATION_CATEGORY: action.result.NOTIFICATION_CATEGORY
      });
    case GET_NOTIFICATIONS[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case MARK_NOTIFICATIONS[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        marking: true
      });
    case MARK_NOTIFICATIONS[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        marking: false,
        marked: true
      });
    case MARK_NOTIFICATIONS[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        marking: false,
        error: action.error
      });
    case CLEAR_NOTIFICATIONS[0]:
      return _objectSpread({}, state);
    case CLEAR_NOTIFICATIONS[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        notifications: []
      });
    case CLEAR_NOTIFICATIONS[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });
    case SAVE_NOTIFICATION_SETTINGS[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        saving: true
      });
    case SAVE_NOTIFICATION_SETTINGS[1]:
      {
        var existingSettingsRow = state.allSettings.find(function (row) {
          return row.unique_id === action.result.unique_id;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          saving: false,
          allSettings: existingSettingsRow ? state.allSettings.map(function (row) {
            return row === existingSettingsRow ? action.result : row;
          }) : [].concat(_toConsumableArray(state.allSettings), [action.result])
        });
      }
    case SAVE_NOTIFICATION_SETTINGS[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        saving: false,
        error: action.error
      });
    default:
      return state;
  }
}
var getUserNotifications = exports.getUserNotifications = function getUserNotifications() {
  return {
    types: GET_NOTIFICATIONS,
    promise: function promise(client) {
      return client.get("/notification/findAll");
    }
  };
};
var markUserNotifications = exports.markUserNotifications = function markUserNotifications(data) {
  return {
    types: MARK_NOTIFICATIONS,
    promise: function promise(client) {
      return client.post("/notification/markAll", {
        data: data
      });
    }
  };
};
var clearUserNotifications = exports.clearUserNotifications = function clearUserNotifications(data) {
  return {
    types: CLEAR_NOTIFICATIONS,
    promise: function promise(client) {
      return client.post("/notification/clearAll", {
        data: data
      });
    }
  };
};
var saveUserNotificationSettings = exports.saveUserNotificationSettings = function saveUserNotificationSettings(data) {
  return {
    types: SAVE_NOTIFICATION_SETTINGS,
    promise: function promise(client) {
      return client.post("/notification/saveSettings", {
        data: data
      });
    }
  };
};
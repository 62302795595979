"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.descriptions = void 0;
var _IntegrationPreview = _interopRequireDefault(require("./IntegrationPreviews/IntegrationPreview"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var descriptions = exports.descriptions = [{
  id: 'freshdesk',
  label: 'Freshdesk',
  category: 'Knowledge Management',
  text: "\n      Publish solution articles directly to your Freshdesk Knowledgebase.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/26THhNiYZaA?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1622119/Embed-tutorial-in-Freshdesk-with-iorad-extension',
      description: 'How to embed iorad tutorials in Freshdesk'
    }]
  }
}, {
  id: 'zendesk',
  label: 'Zendesk',
  category: 'Knowledge Management',
  text: "\n      Publish articles directly to your Zendesk Guide.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/zAvuNcdzJSU?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1621867/Embed-Tutorial-in-Zendesk-with-iorad-extension',
      description: 'How to embed iorad tutorials in Zendesk'
    }]
  }
}, {
  id: 'helpdocs',
  label: 'Helpdocs',
  category: 'Knowledge Management',
  text: "\n      Publish articles directly to your HelpDocs Knowledge base.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/PJiN60sHLzw?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1617341/Embed-tutorial-in-HelpDocs-with-iorad-extension',
      description: 'How to embed an iorad tutorial in Helpdocs'
    }]
  }
}, {
  id: 'helpscout',
  label: 'Help Scout',
  category: 'Knowledge Management',
  text: "\n      Publish articles directly to your Help Scout Docs.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/9eclAxJFwl0?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1622123/Embed-tutorial-in-HelpScout-with-iorad-extension',
      description: 'How to embed an iorad article in Help Scout'
    }]
  }
}, {
  id: 'servicenow',
  label: 'Servicenow',
  category: 'Knowledge Management',
  text: "Publish articles directly to Servicenow Knowledge.",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/rGWCKCs5mBo?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1618991/Embed-tutorials-in-Servicenow-with-iorad-extension',
      description: 'How to embed an iorad article in Servicenow'
    }]
  }
}, {
  id: 'salesforce',
  label: 'Salesforce',
  category: 'Knowledge Management',
  text: "\n      Publish articles directly to Salesforce Knowledge.",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1620605/Embed-tutorial-in-Salesforce-Knowledge-with-iorad-extension',
      description: 'How to embed an iorad article in Salesforce'
    }]
  }
}, {
  id: 'getguru',
  label: 'Guru',
  category: 'Knowledge Management',
  text: "\n      Publish step-by-step cards directly to Guru.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/QwgMkxarUdI?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1621887/Embed-tutorials-in-Guru-with-iorad-extension',
      description: 'How to embed iorad tutorials in Guru'
    }]
  }
}, {
  id: 'freshservice',
  label: 'Freshservice',
  category: 'Knowledge Management',
  text: "\n      Publish solution articles directly to Freshservice Solutions.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/GRzVZKnbzJ8?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 49 seconds'
    }, {
      url: 'https://www.iorad.com/player/1622117/Embed-tutorial-in-Freshservice-with-iorad-extension',
      description: 'How to embed an iorad article in Freshservice'
    }]
  }
}, {
  id: 'confluence',
  label: 'Confluence',
  category: 'Knowledge Management',
  text: "\n      Publish step-by-step solution articles directly to Confluence.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/WEgBlEMoWHU?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1617290/Embed-Tutorial-in-Confluence-with-iorad-extension',
      description: 'How to embed iorad tutorials in Confluence'
    }]
  }
}, {
  id: 'dynamics',
  label: 'Dynamics',
  category: 'Knowledge Management',
  text: "\n      Publish articles directly to Microsoft Dynamics.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  }
}, {
  id: 'canvaslms',
  label: 'Canvas',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Canvas LMS.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1622996/Embed-tutorial-in-Canvas-with-iorad-extension',
      description: 'How to embed an iorad tutorial in Canvas'
    }]
  }
}, {
  id: 'hubspot',
  label: 'Hubspot',
  category: 'Knowledge Management',
  text: "\n      Publish articles directly to Hubspot Knowledge.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/-0KlJO39WEE?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1617278/Embed-tutorial-in-Hubspot-with-iorad-Extension',
      description: 'How to embed an iorad tutorial in Hubspot'
    }]
  }
}, {
  id: 'youtube',
  label: 'Youtube',
  category: 'other',
  text: "\n      Auto-upload video versions of your iorad tutorials.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1609122/YouTube--Connect---Publish',
      description: 'How to Connect & Publish to Youtube'
    }]
  }
}, {
  id: 'gdrive',
  label: 'Google Drive',
  category: 'other',
  text: "\n      Upload Word versions of your iorad tutorials directly to Drive.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1851765/How-to-Connect---Export-to-Google-Drive',
      description: 'How to Connect & Export to Google Drive'
    }]
  }
}, {
  id: 'wordpress',
  label: 'Wordpress',
  category: 'hostings',
  text: "\n      With our famous 5-minute installation, setting up WordPress for the first time is simple.\n      We've created a handy guide to see you through the installation process.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  }
}, {
  id: 'googleAnalytics',
  label: 'Google Analytics',
  category: 'other',
  text: "\n      Receive iorad player events (ie tutorial completion) directly in your Google Analytics Account.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  }
}, {
  id: 'slack',
  label: 'Slack',
  category: 'other',
  text: "\n      Instantly share step-by-step directions directly in a Slack channel.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1845669/Slack--Connect-iorad-to-a-Slack-Workspace',
      description: 'How to Connect iorad to your Slack Workspace'
    }]
  }
}, {
  id: 'intercom',
  label: 'Intercom',
  category: 'Knowledge Management',
  text: "\n      Publish the iorad static list in an Intercom article or put the power of iorad tutorials inside of the Intercom messenger.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/9iD05gZgE7Y?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1695978/Intercom--Connect-iorad-api',
      description: 'How to Connect iorad to Intercom'
    }, {
      url: 'https://www.iorad.com/player/1695812/Intercom--Publish-Step-List-to-Intercom-Articles?',
      description: 'How to Publish iorad tutorial Static Lists to Intercom Articles'
    }]
  }
}, {
  id: 'slab',
  label: 'Slab',
  category: 'Knowledge Management',
  text: "\n      Publish solution articles directly to Slab.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1645701/Embed-tutorial-in-Slab-with-iorad-extension',
      description: 'How to embed an iorad tutorial in Slab'
    }]
  },
  extensionOnly: true
}, {
  id: 'groovehq',
  label: 'GrooveHQ',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to Groove.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/oGCXAe3ceJE?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1645709/Embed-a-tutorial-in-Groove-with-iorad-extension',
      description: 'How to embed an iorad tutorial in GrooveHQ'
    }]
  },
  extensionOnly: true
}, {
  id: 'docebo',
  label: 'Docebo',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Docebo.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1619004/Embed-Tutorials-in-Docebo-with-iorad-extension',
      description: 'How to embed an iorad tutorial in Docebo'
    }]
  },
  extensionOnly: true
}, {
  id: 'litmos',
  label: 'Litmos',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Litmos.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/Dya46Di4bZM?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1622120/Embed-tutorial-in-Litmos-with-iorad-extension',
      description: 'How to embed an iorad tutorial in Litmos'
    }]
  },
  extensionOnly: true
}, {
  id: 'lessonly',
  label: 'Seismic Learning',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Seismic Learning.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.iorad.com/player/1616300/Embed-Tutorial-in-Lessonly-with-iorad-Extension',
      description: 'How to embed iorad tutorials into Seismic Learning formerly known as Lessonly'
    }, {
      url: 'https://www.youtube.com/embed/lYfr9zMQ7e0?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  },
  extensionOnly: true
}, {
  id: '360learning',
  label: '360learning',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in 360Learning.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/W7Mfq45GALI?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1619565/Embed-tutorial-in-360Learning-with-iorad-extension',
      description: 'How to embed iorad tutorials in 360learning'
    }]
  },
  extensionOnly: true
}, {
  id: 'workramp',
  label: 'Workramp',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Workramp.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/YEsP9U7n4EA?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1616311/Embed-tutorial-in-Workramp-with-iorad-extension',
      description: 'Embedding iorad tutorials in Workramp'
    }]
  },
  extensionOnly: true
}, {
  id: 'talentlms',
  label: 'TalentLMS',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in TalentLMS.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/vV0DZ3Ayw1Y?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1616310/Embed-Tutorial-in-Talent-LMS-with-iorad-extension',
      description: 'Embedding iorad tutorials in TalentLMS'
    }]
  },
  extensionOnly: true
}, {
  id: 'skilljar',
  label: 'Skilljar',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Skilljar.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/9oXb9KnlrcY?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1621763/Embed-tutorial-in-Skilljar-with-iorad-extension',
      description: 'How to embed an iorad article in Skilljar'
    }]
  },
  extensionOnly: true
}, {
  id: 'coassemble',
  label: 'Coassemble',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Coassemble.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/cPj2nD_nYRk?rel=0&autohide=1&showinfo=0',
      description: 'Tutorials in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1621766/Embed-tutorial-in-Coassemble-with-iorad-extension',
      description: 'How to embed an iorad article in Coassemble'
    }]
  },
  extensionOnly: true
}, {
  id: 'trainual',
  label: 'Trainual',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to a course in Trainual.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1640106/Embed-Tutorial-in-Trainual-with-iorad-extension',
      description: 'How to embed an iorad article in Trainual'
    }]
  },
  extensionOnly: true
}, {
  id: 'easygenerator',
  label: 'Easy Generator',
  category: 'Content Authoring',
  text: "\n      Publish tutorials directly to a course in EasyGenerator.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1852486/Easy-Generator---Embed-tutorial-with-iorad-extension',
      description: 'How to Embed an iorad tutorial in Easy Generator'
    }]
  },
  extensionOnly: true
}, {
  id: 'absorblms',
  label: 'AbsorbLMS',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in AbsorbLMS.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1645057/AbsorbLMS---How-to-Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad article in AbsorbLMS'
    }]
  },
  extensionOnly: true
}, {
  id: 'notion',
  label: 'Notion',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to a course in Notion.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/oLumTTHRxZE?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1673837/Notion--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad article in Notion'
    }]
  },
  extensionOnly: true
}, {
  id: 'rise',
  label: 'Rise Articulate 360',
  category: 'Content Authoring',
  text: "\n      Publish tutorials directly to a course in Rise Articulate 360.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1619379/Articulate--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial in Rise Articulate 360'
    }]
  },
  extensionOnly: true
}, {
  id: 'hownow',
  label: 'HowNow',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in HowNow.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1731192/HowNow--Embed-a-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial in HowNow'
    }]
  },
  extensionOnly: true
},
// {
//  id: 'bigtincan',
//  label: 'Bigtincan',
//  category: 'Learning Management',
//   text: `
//     Publish tutorials directly to a course in Bigtincan.
//   `,
//   previewComponent: IntegrationPreview,
//   previewProps: {
//     tutorials: [
//      {
//         url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//        description: 'Solution article creation in under 30 seconds',
//      },
//     {
//        url: 'https://www.iorad.com/player/1852312/Bigtincan---Embed-tutorial-with-the--iorad-extension',
//        description: 'How to embed an iorad tutorial in Bigtincan',
//      },
//    ],
//  },
//  extensionOnly: true,
// },
{
  id: 'northpass',
  label: 'Gainsight CE',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Gainsight CE.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1833551/Northpass---Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into NorthPass'
    }]
  },
  extensionOnly: true
}, {
  id: 'seismic',
  label: 'Seismic',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to Seismic content manager.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.iorad.com/player/1901826/Seismic--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Seismic'
    }, {
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  }
}, {
  id: 'dokeos',
  label: 'Dokeos',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Dokeos.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  },
  extensionOnly: true
}, {
  id: 'learnermobile',
  label: 'Learnermobile',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Learner Mobile.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1902198/Learnermobile--Embed-tutorial-with-iorad-extension',
      description: 'How to embed and iorad tutorial into Learner Mobile'
    }]
  },
  extensionOnly: true
}, {
  id: 'thoughtindustries',
  label: 'Thought Industries',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to a course in Thought Industries.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1909244/Thought-Industries--Embed-tutorial-with-iorad-extension?iframeHash=watchsteps-1',
      description: 'How to embed an iorad tutorial into Thought Industries'
    }]
  },
  extensionOnly: true
}, {
  id: 'tovuti',
  label: 'Tovuti',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to Tovuti interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/1988251/Tovuti--Embed-a-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Tovuti'
    }]
  },
  extensionOnly: true
}, {
  id: 'ewebinar',
  label: 'eWebinar',
  category: 'other',
  text: "\n      Publish tutorials directly to eWebinar interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2001231/eWebinar--Embed-a-tutorial-with-the-iorad-extension',
      description: 'How to embed an iorad tutorial into eWebinar'
    }]
  },
  extensionOnly: true
},
//  {
//    id: 'ceros',
//    label: 'Ceros',
//    category: 'Content Authoring',
//    text: `
//      Publish tutorials directly to Ceros interactive content.
//    `,
//    previewComponent: IntegrationPreview,
//    previewProps: {
//      tutorials: [
//        {
//          url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//          description: 'Solution article creation in under 30 seconds',
//        },
//        {
//          url: 'https://www.iorad.com/player/2001309/Ceros--Embed-tutorial-with-iorad-extension',
//          description: 'How to embed an iorad tutorial into Ceros',
//        },
//      ],
//    },
//    extensionOnly: true,
//  },
{
  id: 'thinkific',
  label: 'Thinkific',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to Thinkific interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2020412/Thinkific--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Thinkific'
    }]
  },
  extensionOnly: true
},
//  {
//    id: 'elucidat',
//    label: 'Elucidat',
//    category: 'Learning Management',
//    text: `
//      Publish tutorials directly to Elucidat interactive content.
//    `,
//    previewComponent: IntegrationPreview,
//    previewProps: {
//      tutorials: [
//        {
//          url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//          description: 'Solution article creation in under 30 seconds',
//        },
//        {
//          url: 'https://www.iorad.com/player/2020400/Elucidat--Embed-tutorial-with-the-iorad-extension',
//          description: 'How to embed an iorad tutorial into Elucidat',
//        },
//      ],
//    },
//    extensionOnly: true,
//  },
{
  id: 'brightspace',
  label: 'Brightspace',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to Brightspace interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2020355/Brightspace--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Brightspace'
    }]
  },
  extensionOnly: true
}, {
  id: 'prezi',
  label: 'Prezi',
  category: 'Content Authoring',
  text: "\n      Publish tutorials directly to Prezi interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      // todo fix this link specific for Prezi
      url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Prezi'
    }]
  },
  extensionOnly: true
}, {
  id: 'schoology',
  label: 'Schoology',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to Schoology interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2094589/Schoology--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Schoology'
    }]
  },
  extensionOnly: true
}, {
  id: 'matrixlms',
  label: 'MATRIX LMS',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to MATRIX LMS interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2096713/MatrixLMS--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into MATRIX LMS'
    }]
  },
  extensionOnly: true
}, {
  id: 'clickup',
  label: 'ClickUp',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to clickup interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2112798/Clickup--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into ClickUp'
    }]
  },
  extensionOnly: true
}, {
  id: 'continu',
  label: 'Continu',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to Continu interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2055266/Continu---Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Continu'
    }]
  },
  extensionOnly: true
},
// {
//   id: 'ihgskillsacademy',
//    label: 'IHG Skills Academy',
//   category: 'Learning Management',
//   text: `
//     Publish tutorials directly to IHG Skills Academy interactive content.
//   `,
//    previewComponent: IntegrationPreview,
//   previewProps: {
//     tutorials: [
//       {
//         url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//         description: 'Solution article creation in under 30 seconds',
//       },
//       {
//         url: 'https://www.iorad.com/player/2112779/IHG--Embed-tutorial-with-iorad-extension',
//         description: 'How to embed an iorad tutorial into IHG Skills Academy',
//       },
//     ],
//   },
//   extensionOnly: true,
// },
{
  id: 'learnupon',
  label: 'LearnUpon',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to LearnUpon interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2112774/LearnUpon--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into LearnUpon'
    }]
  },
  extensionOnly: true
}, {
  id: 'usewhale',
  label: 'Whale',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to Whale interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2112999/Whale--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Whale'
    }]
  },
  extensionOnly: true
},
// {
//   id: 'questionmark',
//   label: 'Questionmark',
//   category: 'Learning Management',
//   text: `
//     Publish tutorials directly to Questionmark interactive content.
//   `,
//   previewComponent: IntegrationPreview,
//   previewProps: {
//     tutorials: [
//      {
//        url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//        description: 'Solution article creation in under 30 seconds',
//       },
//       {
//         url: 'https://www.iorad.com/player/2112790/Questionmark--Embed-tutorial-with-iorad-extension',
//         description: 'How to embed an iorad tutorial into Questionmark',
//       },
//     ],
//   },
//   extensionOnly: true,
// },
{
  id: 'ispringlearn',
  label: 'iSpring Learn',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to iSpring Learn interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2266855/ISpring-Learn---Embed-a-tutorial-with-the-iorad-extension',
      description: 'How to embed an iorad tutorial into iSpring Learn'
    }]
  },
  extensionOnly: true
},
// {
//   id: 'gcubelms',
//   label: 'gcube LMS',
//   category: 'Learning Management',
//   text: `
//     Publish tutorials directly to gcube LMS interactive content.
//   `,
//   previewComponent: IntegrationPreview,
//   previewProps: {
//     tutorials: [
//      {
//         url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//         description: 'Solution article creation in under 30 seconds',
//       },
//       {
//         // todo fix this link specific for gcubelms
//         url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
//        description: 'How to embed an iorad tutorial into gcube LMS',
//      },
//     ],
//   },
//  extensionOnly: true,
// },
{
  id: 'moodle',
  label: 'Moodle',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to Moodle interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2266892/Moodle--Embed-a-tutorial-with-the-iorad-extension',
      description: 'How to embed an iorad tutorial into Moodle'
    }]
  },
  extensionOnly: true
},
// {
//  id: 'tiled',
//  label: 'Tiled',
//  category: 'Content Authoring',
//  text: `
//    Publish tutorials directly to Tiled interactive content.
//  `,
//  previewComponent: IntegrationPreview,
//  previewProps: {
//    tutorials: [
//     {
//       url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//       description: 'Solution article creation in under 30 seconds',
//     },
//     {
//       url: 'https://www.iorad.com/player/2266919/Tiled--Embed-tutorial-with-iorad-extension',
//       description: 'How to embed an iorad tutorial into Tiled',
//      },
//   ],
// },
//  extensionOnly: true,
// },
// {
//  id: 'learntowin',
//  label: 'Learn To Win',
//  category: 'Learning Management',
//  text: `
//    Publish tutorials directly to Learn To Win interactive content.
// `,
// previewComponent: IntegrationPreview,
// previewProps: {
//   tutorials: [
//      {
//       url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//       description: 'Solution article creation in under 30 seconds',
//      },
//      {
//       // todo fix this link specific for Learn To Win
//        url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
//        description: 'How to embed an iorad tutorial into Learn To Win',
//      },
//    ],
//  },
//  extensionOnly: true,
// },
{
  id: 'supered',
  label: 'Supered',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to Supered interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2266937/Supered--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Supered'
    }]
  },
  extensionOnly: true
}, {
  id: 'canva',
  label: 'Canva',
  category: 'Content Authoring',
  text: "\n      Publish tutorials directly to Canva interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2267142/Canva--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Canva'
    }]
  },
  extensionOnly: true
},
// {
//   id: 'flowla',
//   label: 'Flowla',
//   category: 'other',
//   text: `
//     Publish tutorials directly to Flowla interactive content.
//   `,
//   previewComponent: IntegrationPreview,
//   previewProps: {
//     tutorials: [
//       {
//         url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//         description: 'Solution article creation in under 30 seconds',
//       },
//       {
//        // todo fix this link specific for flowla
//         url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
//         description: 'How to embed an iorad tutorial into Flowla',
//       },
//     ],
//   },
//   extensionOnly: true,
// },
// {
//  id: 'trumpet',
//  label: 'Trumpet',
//  category: 'other',
//  text: `
//    Publish tutorials directly to Trumpet interactive content.
//  `,
//  previewComponent: IntegrationPreview,
//  previewProps: {
//    tutorials: [
//     {
//        url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//        description: 'Solution article creation in under 30 seconds',
//     },
//      {
//       // todo fix this link specific for trumpet
//       url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
//       description: 'How to embed an iorad tutorial into Trumpet',
//      },
//    ],
//  },
//  extensionOnly: true,
// },
{
  id: 'cd2learning',
  label: 'CD2 Learning',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to CD2 Learning interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      // todo fix this link specific for CD2 Learning
      url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into CD2 Learning'
    }]
  },
  extensionOnly: true
}, {
  id: 'skyprep',
  label: 'SkyPrep',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to SkyPrep interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2267159/Skyprep--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into SkyPrep'
    }]
  },
  extensionOnly: true
},
// {
//   id: 'partnerportal',
//   label: 'Partner Portal',
//   category: 'Learning Management',
//   text: `
//     Publish tutorials directly to partnerportal.io interactive content.
//   `,
//   previewComponent: IntegrationPreview,
//   previewProps: {
//     tutorials: [
//      {
//        url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//         description: 'Solution article creation in under 30 seconds',
//       },
//      {
//        // todo fix this link specific for partnerportal.io
//        url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
//        description: 'How to embed an iorad tutorial into partnerportal.io',
//       },
//     ],
//  },
//  extensionOnly: true,
// },
{
  id: 'eloomi',
  label: 'eloomi',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to eloomi.com interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2452227/Eloomi---Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into eloomi.io'
    }]
  },
  extensionOnly: true
}, {
  id: 'evolveauthoring',
  label: 'Evolve Authoring',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly to evolveauthoring.com interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      // todo fix this link specific for evolveauthoring.com
      url: 'https://www.iorad.com/player/2301876/EvolveAuthoring--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into evolveauthoring.io'
    }]
  },
  extensionOnly: true
}, {
  id: 'document360',
  label: 'Document 360',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to document360.io interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }, {
      url: 'https://www.iorad.com/player/2267178/Document360--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into document360.io'
    }]
  },
  extensionOnly: true
}, {
  id: 'zoho',
  label: 'Zoho',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to Zoho interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.iorad.com/player/2298981/Zoho--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Zoho'
    }, {
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  },
  extensionOnly: true
}, {
  id: 'gainsight',
  label: 'Gainsight CC',
  category: 'Knowledge Management',
  text: "\n      Publish tutorials directly to Gainsight interactive content.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.iorad.com/player/2451521/Gainsight-CC---Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Gainsight'
    }, {
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  },
  extensionOnly: false
}, {
  id: 'googlemail',
  label: 'Gmail',
  category: 'other',
  text: "\n      Insert a tutorial while composing an email in Gmail.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.iorad.com/player/2329391/Gmail--Embed-tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Gmail'
    }, {
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  },
  extensionOnly: true
}, {
  id: 'googleclassroom',
  label: 'Google Classroom',
  category: 'Learning Management',
  text: "\n      Publish tutorials directly into Google Classroom.\n    ",
  previewComponent: _IntegrationPreview.default,
  previewProps: {
    tutorials: [{
      url: 'https://www.iorad.com/player/1778466/Google-Classroom--Embed-Tutorial-with-iorad-extension',
      description: 'How to embed an iorad tutorial into Google Classroom'
    }, {
      url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
      description: 'Solution article creation in under 30 seconds'
    }]
  },
  extensionOnly: true
}
// {
//  id: 'monday',
//    label: 'Monday',
//    category: 'Knowledge Management',
//    text: `
//      Publish tutorials directly to Monday interactive content.
//    `,
//    previewComponent: IntegrationPreview,
//    previewProps: {
//      tutorials: [
//        {
// todo fix this link specific for Monday.com
//         url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
//         description: 'How to embed an iorad tutorial into monday.com',
//       },
//       {
//         url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//         description: 'Solution article creation in under 30 seconds',
//       },
//     ],
//   },
//   extensionOnly: true,
// },
// {
//   id: 'gracehill',
//   label: 'Grace Hill',
//   category: 'Knowledge Management',
//   text: `
//     Publish tutorials directly to Grace Hill interactive content.
//   `,
//   previewComponent: IntegrationPreview,
//   previewProps: {
//     tutorials: [
//       {
//         // todo fix this link specific for gracehill.com
//         url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
//         description: 'How to embed an iorad tutorial into gracehill.com',
//       },
//       {
//         url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//         description: 'Solution article creation in under 30 seconds',
//       },
//     ],
//   },
//   extensionOnly: true,
// },
// {
//   id: 'gainsightcloud',
//   label: 'Gainsight Cloud',
//   category: 'Knowledge Management',
//   text: `
//     Publish tutorials directly to Gainsight Cloud interactive content.
//   `,
//   previewComponent: IntegrationPreview,
//   previewProps: {
//     tutorials: [
//       {
//         // todo fix this link specific for gainsight.com
//         url: 'https://www.iorad.com/player/2095515/Coming-Soon--Embed-tutorial-with-iorad-extension',
//         description: 'How to embed an iorad tutorial into Gainsight Cloud',
//       },
//       {
//         url: 'https://www.youtube.com/embed/P4cJ8vznLjQ?rel=0&autohide=1&showinfo=0',
//         description: 'Solution article creation in under 30 seconds',
//       },
//     ],
//   },
//   extensionOnly: true,
// },
];